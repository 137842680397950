<template>
    <b-overlay :show="show" rounded="sm">
        <b-card-code no-body title="Update role">
            <b-row class="pb-2">
                <b-col md="6">
                    <div class="px-2">
                    <!-- form -->
                    <validation-observer ref="roleUpdateForm">
                        <b-form
                        class="auth-register-form mt-2"
                        @submit.prevent="updateRole"
                        >
                        <!-- name -->
                        <b-form-group>
                            <validation-provider
                            #default="{ errors }"
                            name="name"
                            vid="name"
                            rules="required"
                            >
                            <b-form-input
                                id="name"
                                v-model="name"
                                name="name"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Name"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group>
                            <v-select
                                v-model="selected_permissions"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                multiple
                                label="name"
                                :options="permissions"
                                placeholder="Permissions"
                            />
                        </b-form-group>

                        <b-button
                            variant="primary"
                            type="submit"
                            :disabled="btnDisabled"
                        >
                            Update role
                        </b-button>
                        <div
                            v-if="loading"
                            class="d-flex justify-content-center mt-2"
                        >
                            <b-spinner variant="primary" label="Loading..." />
                        </div>
                        </b-form>
                    </validation-observer>
                    </div>
                </b-col>
            </b-row>
        </b-card-code>
    </b-overlay>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BOverlay, BButton, BFormInput, BForm, BFormGroup, BRow, BCol, BInputGroup, BInputGroupAppend } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { BSpinner } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from 'vue-select'

export default {
    components: {
        BOverlay,
        BCardCode,
        BRow,
        BCol,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        ValidationProvider,
        ValidationObserver,
        BInputGroupAppend,
        BSpinner,
        vSelect
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            show: true,
            name: "",
            selected_permissions: null,
            permissions: [],

            // validation
            required,
            btnDisabled: false,
            loading: false,
            isAvailableUsername: 1,
            errors: [],
            slugFlag: false,
            slugString: "",
        }
    },
    computed: {        
        passwordToggleIcon() {
            return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
        },
        cPasswordToggleIcon() {
            return this.cPasswordFieldType === "cPassword" ? "EyeIcon" : "EyeOffIcon";
        },
        errorExists() {
            if(this.username.length > 0) {
                if(this.isAvailableUsername == 0) {
                  this.errors.push('That username already exists')
                  return false
                } else {
                  this.errors = []
                  return null
                }
            } else {
                this.errors.push('The Username field is required')
                return false
            }
        }
    },
    created() {
        //Return permissions list
        this.$http.get("/api/auth/admin/permissions")
            .then((res) => {
                if(res != undefined) {
                    this.permissions = res.data
                }
            })
            .catch((err) => {
                console.log(err)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong, please login again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            })
    },
    mounted() {
        let id = this.$route.params.id
        //Return permissions list
        this.$http.get("/api/auth/admin/roles/" + id)
            .then((res) => {
                this.name = res.data.name,
                this.selected_permissions = res.data.permissions
                
                // Hide overlay
                this.show = false
            })
            .catch((err) => {
                console.log(err)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong, please login again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });

                // Hide overlay
                this.show = false
            })
    },
    methods: {
        // Request to update role
        updateRole() {
            // Show overlay
            this.show = true

            // Get param from url
            let id = this.$route.params.id

            let role = {
                "name": this.name,
                "permissions": this.selected_permissions,
            }

            this.$refs.roleUpdateForm.validate().then(success => {
                if (success) {
                    this.$http.patch("/api/auth/admin/roles/" + id, role)
                    .then((res) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Role successfully updated",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });

                        // Hide overlay
                        this.show = false
                    })
                    .catch((error) => {
                        console.log(error)

                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error!",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });

                        // Hide overlay
                        this.show = false
                    });
                }
            });
        },
    },
}
</script>

<style>
    .vs__selected {
        background-color: #59949c!important;
    }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
